@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "LibreBaskerville-Regular";
  src: url("../public/fonts/LibreBaskerville-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "LibreBaskerville-Bold";
  src: url("../public/fonts/LibreBaskerville-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "LibreBaskerville-Italic";
  src: url("../public/fonts/LibreBaskerville-Italic.ttf") format("truetype");
}

html {
  background: #111;
  color: #aaa;
  font-family: "LibreBaskerville-Bold";
}

h1 {
  font-size: 28px;
  line-height: 42px;
}

@media (min-width: 1024px) {
  h1 {
    font-size: 72px;
    line-height: 88px;
  }
}

a,
a:visited {
  color: #26ac62;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    height: 4px;
    background-color: #26ac62;
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 500ms ease;
  }

  &:hover:before {
    transform-origin: bottom left;
    transform: scaleX(1);
  }
}
